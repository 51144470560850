footer {
    box-shadow: 0 50vh 0 50vh #000;
}

.footer__container > * {
    margin: 1rem 0;
}

.footer__overlay::after {
    position: absolute;
    content: " ";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #333333;
    opacity: 0.5;
}