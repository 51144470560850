.navbar__padding {
    height: 120px;
}

.navbar__bg-container.visible {
    transform: translateY(0);
    overflow: visible;
}

.navbar__bg-container {
    position: fixed;
    top: 0;
    width: 100%;
    transform: translateY(-100%);
    z-index: 10;
    overflow: hidden;
    transition: transform ease-in 300ms;
    padding: 1% 0;
}

.navbar__logo {
    position: relative;
    background-color: #000;
}


.navbar-bg-color {
    background-color: #00000080;
}

.navbar__content > li {
    color: #000;
    padding: 0 1rem;
}

.navbar__content > li > a {
    font-family: montserrat, sans-serif;
    text-decoration: none;
    
    font-weight: 600;
}

/* .navbar__content > li > a.active,
.navbar__content > li > a:hover {
    border-bottom: 2px solid currentColor;
} */

.mobile-nav__container {
    transition: transform ease-in-out 300ms;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 50%;
    min-width: 300px;

    transform: translate(100%, 0);
}

.mobile-nav__container.visible {
    transform: translate(0, 0);
}

.mobile-nav__container > * {
    margin-bottom: 1em;
}

.mobile-nav__container > a {
    text-decoration: none;
}



@media screen and (max-width: 1023px) {
    .navbar-bg-color {
        background-color: #000000;
    }
}

@media screen and (max-width: 1270px) {
    .navbar__content > li {
        padding: 0 0.5rem;
        font-size: small;
    }
}